import React, { useState, useEffect } from "react";
import { CircularProgress, LinearProgress, Box } from "@mui/material";
import Logo from "../Logo";

const Loading = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {  // Changed from 100 to 99
                    clearInterval(timer); // Stop interval when progress hits 99
                    return oldProgress;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 30);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ 
            backgroundColor: "#fff", 
            width: "100vw", 
            height: "100vh", 
            display: "flex",
            flexDirection: "column", 
            gap: 4,
            justifyContent: "center", 
            alignItems: "center" 
        }}>
            <Logo />
            <LinearProgress 
                variant="determinate" 
                value={progress} 
                style={{width: "80%", height: "10px", borderRadius: "32px", backgroundColor: "#ddd"}}
            />
        </Box>
    )
}

export default Loading;
