import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import greetingTime from "greeting-time";

import { Box } from "@mui/material";
import { Card } from "@mui/joy";

import Nav from "../_components/Nav";
import AnalyticsReport from "../_components/charts/AnalyticsReport";
import ManageList from "../_components/lists/ManageList";

const drawerWidth = 240;

const Dashboard = () => {
    const [user, setUser] = useState({});
    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" });

    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);

    const getUser = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/users/get-user`, { decodedToken });
            setUser(response.data.user);
            // console.log(response.data.user);
        } catch (error) {
            setAlert({ open: true, severity: "error", message: `Error fetching user details. ${error}` });
        }
    }
    useEffect(() => {
        getUser();
    }, []);

    return (
        <Box sx={{ display: "flex", "padding-top": 60 }}>
            <Nav />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <h3>{greetingTime(new Date())}, {user.fName} {user.lName}</h3>
                <Card variant="outlined"><ManageList initialArticles={null} /></Card>
                {/* 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 */}
                <Card variant="outlined"><AnalyticsReport /></Card>
            </Box>
        </Box>
    )
}

export default Dashboard;