import React, { useState, useEffect } from "react";
import Nav from "../_components/Nav";
import jwt_decode from "jwt-decode"
import axios from "axios";
import lodash from "lodash";

import { Card } from "@mui/joy";
import { Box, Typography, Divider, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const drawerWidth = 240;

const Profile = () => {
    const [user, setUser] = useState({});
    const [error, setError] = useState("");

    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);

    const getUser = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/users/get-user`, { decodedToken });
            setUser(response.data.user);
            // console.log(response.data.user);
        } catch (error) {
            setError("Error fetching user details")
        }
    }
    useEffect(() => {
        getUser()
    }, []);
    return (
        <Box sx={{ display: "flex", "padding-top": 60 }}>
            <Nav />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Card>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>Profile</Typography>
                    <Typography variant="subtitle1">
                        Welcome, {lodash.capitalize(user.fName) + " " + lodash.capitalize(user.lName)}
                    </Typography>
                    <Divider />
                    {/* <TableContainer>
                        <Table>
                            <TableHead>Details</TableHead>
                            <TableBody>

                                {Object.entries(user).map(([keyName, subject], index) => (

                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{keyName}</TableCell>
                                        <TableCell>{subject}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer> */}
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>Username</Typography>
                    <Typography>{user.username}</Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>Email</Typography>
                    <Typography>{user.email}</Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>First name</Typography>
                    <Typography>{user.fName}</Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>Last name</Typography>
                    <Typography>{user.lName}</Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>Role</Typography>
                    <Typography>{user.role}</Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>Created At</Typography>
                    <Typography>{user.createdAt ?? "null"}</Typography>
                </Card>
            </Box>
        </Box>
    )
}

export default Profile;