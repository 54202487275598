import React, { useState } from "react";

import { Snackbar, Alert } from "@mui/material";

const SnackbarAlert = ({ alert, setAlert }) => {
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ ...alert, open: false });
    }

    return (
        <Snackbar
            open={alert.open}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            <Alert
                onClose={handleCloseSnackbar}
                severity={alert.severity}
            >
                {alert.message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarAlert;