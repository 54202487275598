//REACT
import React, { useState, useEffect } from "react";

//MUI COMPONENTS
import { Typography, Button, Autocomplete, Textarea, Grid, Alert, Box, Stack, Divider, Tabs, Tab, TabPanel, TabList } from "@mui/joy"
import { FormControl, FormLabel, IconButton, Input } from "@mui/material";

//MUI ICONS
import SendIcon from '@mui/icons-material/Send';

//COMPONENTS
import Navbar from "../../_components/Navbar/Navbar";
import Footer from "../../_components/Footer/Footer";
import { Form } from "react-router-dom";

//MISC
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios"
// const url = "http://localhost:3001/api/articles";
import config from "../../config";
const url = `${config.apiUrl}/api/articles`;


const Report = () => {
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const [reportArticle, setReportArticle] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const fetchArticles = async () => {
        if (!loading) return;
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-articles`);
        const fetchedArticles = await response.json();
        setArticles(fetchedArticles);
        setLoading(false);
    }

    const handleSendReport = () => {
        // setShowAlert(true);

        // Axios({
        //     method: "POST",
        //     data: {
        //         article: reportArticle
        //     },
        //     withCredentials: true,
        //     url: "http://localhost:3001/sendReport",
        // }).then(() => {
        //     setShowAlert(true);
        // });
    }

    return (
        <div>
            <Navbar />
            <header className="top-container">
                {showAlert && <Alert color="success" variant="soft"><Typography level="h5">Skickad</Typography></Alert>}

                <h1>Rapportera fel</h1>
                <p>Har du något förslag på artiklar som du tycker saknas? Har du några andra synpunkter på webbsidan eller innehåll? Lämna dina synpunkter i formuläret nedan.</p>
            </header>
            <Stack spacing={2} direction="row">
                <Box>
                    <Tabs defaultValue={0}>
                        <TabList>
                            <Tab value={0}>Innehåll</Tab>
                            <Tab value={1}>Krasch</Tab>
                            <Tab value={2}>Förslag</Tab>
                        </TabList>
                        <TabList>
                            <TabPanel value={0}>
                                <FormControl>
                                    <FormLabel>Välj artikel</FormLabel>
                                    <Autocomplete
                                        required={true}
                                        onOpen={fetchArticles}
                                        variant="soft"
                                        placeholder="Vilken är Sveriges största..."
                                        getOptionLabel={(option) => option.title}
                                        options={articles}
                                        onChange={(e, value) => setReportArticle(value)}
                                    />
                                    <FormLabel>Beskriv felet</FormLabel>
                                    <Textarea variant="soft" minRows={3} placeholder="T.ex. innehållet stämmer inte längre..." />
                                    {/* <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY} /> */}
                                    <Button onClick={handleSendReport} variant="soft" color="neutral">Skicka<SendIcon /></Button>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={1}>
                                <FormControl>
                                    <FormLabel>Beskriv felet utförligt</FormLabel>
                                    <Textarea variant="soft" minRows={3} placeholder="T.ex. hemsidan krashade när jag klickade på 'om oss'..." />
                                    {/* <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY} /> */}
                                    <Button onClick={handleSendReport} variant="soft" color="neutral">Skicka<SendIcon /></Button>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={2}>
                                <FormControl>
                                    <FormLabel>Beskriv ditt förlsag</FormLabel>
                                    <Textarea variant="outlined" minRows={3} placeholder="T.ex. lägg till 'världens högsta byggnad'..." />
                                    {/* <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY} /> */}
                                    <Button onClick={handleSendReport} variant="soft" color="neutral">Skicka<SendIcon /></Button>
                                </FormControl>
                            </TabPanel>
                        </TabList>
                    </Tabs>

                </Box>
                {reportArticle && (
                    <>
                        <Divider orientation="vertical" />
                        <Box>

                            <b>{reportArticle.title}</b>
                            <p>{reportArticle.content}</p>
                            <Typography level="body5">{reportArticle.updated}</Typography>
                            <Typography level="body5">{reportArticle.source}</Typography>


                        </Box>
                    </>
                )}
            </Stack>


        </div >

    )
}

export default Report;