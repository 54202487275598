import React, { useState, useEffect } from "react";

import Nav from "../_components/Nav";
import ArticleList from "../_components/lists/ArticleList";
import Loading from "../../../_components/Loading/Loading";
import CreateModal from "../_components/modals/CreateModal";
import ManageList from "../_components/lists/ManageList";
import AnalyticsReport from "../_components/charts/AnalyticsReport";
import BarArticles from "../_components/charts/BarArticles";

import { Card, Button, Typography, Grid } from "@mui/joy";
import { Box, Tabs, Tab } from "@mui/material";

import config from "../../../config";
// const url = `${config.apiUrl}/api/articles`;
const url = "http://localhost:3001/api/articles";

const drawerWidth = 240;

// OPENAI API som ställer en fråga när artikeln inte finns i databasen. När frågan är ställd, kommer den att skickas till ett speciellt register,
// ...där vi kan godkänna artikeln, så att den kommer in i vanliga kollektionen. Om frågan är AI genererad, ska den markeras som det.

const Articles = () => {
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);

    const [drafts, setDrafts] = useState([])
    const [selectedTab, setSelectedTab] = useState(0);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);

    const [categories, setCategories] = useState([{ _id: "003", name: "Största", createdAt: "21000323132", updatedAt: null }]);

    const fetchArticles = async () => {
        const responseArticles = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-articles`);
        const fetchedArticles = await responseArticles.json();
        const responseCategories = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-categories`);
        const fetchedCategories = await responseCategories.json();
        const responseDrafts = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/drafts/get-drafts`);
        const fetchedDrafts = await responseDrafts.json();        
        setArticles(fetchedArticles);
        setDrafts(fetchedDrafts);
        setCategories(fetchedCategories);
        setLoading(false);
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        fetchArticles();
    }, [])

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <Box sx={{ display: "flex", "padding-top": 60 }}>
            <Nav />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                {/* CONTENT START */}
                <Grid container spacing={2} sx={{ mb: 1 }}>

                    <Grid item>
                        <Card variant="outlined" color="neutral" sx={{mb: 1}}>
                            <p style={{margin: 0}}><b>Articles: </b>{articles.length}</p>
                            <p style={{margin: 0}}><b>Drafts: </b>{drafts.length}</p>
                        </Card>
                        <Card variant="outlined" color="neutral">
                            <Typography level="h6">Actions</Typography>
                            <Button onClick={() => setShowCreateModal(true)}>Create</Button>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card variant="outlined">
                            <BarArticles articles={articles} />
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card variant="outlined"><ManageList initialArticles={articles} /></Card>
                    </Grid>
                </Grid>



                <Card variant="outlined">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label="Articles" />
                            <Tab label="Drafts" />
                            <Tab label="Manage" />
                        </Tabs>
                    </Box>
                    {selectedTab === 0 && (
                        <ArticleList articles={articles} setArticles={setArticles} type="articles" />
                    )}
                    {selectedTab === 1 && (
                        <ArticleList articles={drafts} setArticles={setDrafts} type="drafts" />
                    )}
                    {selectedTab === 2 && (
                        <ManageList initialArticles={articles} />
                    )}


                </Card>

                <CreateModal
                    showCreateModal={showCreateModal}
                    setShowCreateModal={setShowCreateModal}
                    showCategoryModal={showCategoryModal}
                    setShowCategoryModal={setShowCategoryModal}
                    articles={articles}
                    setArticles={setArticles}
                    categories={categories}
                    setCategories={setCategories}
                />
                {/* CONTENT END */}
            </Box>

        </Box>
    )
}

export default Articles;