import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ListLoading from '../../../../_components/Loading/ListLoading';

const BarArticles = ({ articles }) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const countCategories = (articles) => {
        const categoryCount = {};
        articles.forEach(article => {
            if (categoryCount.hasOwnProperty(article.option)) {
                categoryCount[article.option]++;
            } else {
                categoryCount[article.option] = 1;
            }
        });
        return categoryCount;
    };

    const categoryData = countCategories(articles);

    const data = {
        labels: Object.keys(categoryData),
        datasets: [
            {
                label: "Articles",
                data: Object.values(categoryData),
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            }
        ]
    }

    const options = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
            },
        },
    };

    return (
        <Bar data={data} options={options} />
    )
}

export default BarArticles;
