import React, { useState, useEffect } from "react";
import Nav from "../_components/Nav";
import TableRowWithCell from "../_components/lists/TableRowWithCell";
import jwt_decode from "jwt-decode"
import axios from "axios";
import lodash from "lodash";

import ObjectID from 'bson-objectid';


import { Card, Button, Typography, Modal, ModalClose, ModalDialog, Input } from "@mui/joy";
import { Box, Alert, Select, MenuItem, Divider, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, TableFooter, TablePagination, IconButton, FormControl, FormLabel } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UserList from "../_components/lists/UserList";
import RegisterModal from "../_components/modals/RegisterModal";

const drawerWidth = 240;


const Users = () => {
    const [users, setUsers] = useState([]);
    const [alertError, setAlertError] = useState("");
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const [error, setError] = useState(null);

    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);

    const getUsers = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/users/get-users`, { decodedToken });
            setUsers(response.data);
        } catch (error) {
            setError("Error fetching users")
        }
    }
    useEffect(() => {
        getUsers()
    }, []);

    return (
        <Box sx={{ display: "flex", "padding-top": 60 }}>
            <Nav />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Typography level="h3">Manage Users</Typography>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item>
                        <Card variant="outlined" color="neutral">
                            <Typography level="h6">Users</Typography>
                            <Typography level="body2">{users.length}</Typography>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card variant="outlined" color="neutral">
                            <Typography level="h6">Actions</Typography>
                            <Button onClick={() => setShowRegisterModal(true)}>Register</Button>
                        </Card>
                    </Grid>
                </Grid>
                
                <RegisterModal showRegisterModal={showRegisterModal} setShowRegisterModal={setShowRegisterModal} setUsers={setUsers} setAlertError={setAlertError} alertError={alertError} />

                <UserList users={users} setUsers={setUsers} />
            </Box>
        </Box>
    )
}

export default Users;