import React, { useState, useEffect } from "react";
import axios from "axios";

import { Card, IconButton, Link, Typography } from "@mui/joy";
import { Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, Box, Grid } from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import TableRowWithCell from "./TableRowWithCell";
import EditArticleModal from "../modals/EditArticleModal";
import ListLoading from "../../../../_components/Loading/ListLoading";

const ManageList = ({ initialArticles }) => {
    const [articles, setArticles] = useState(initialArticles || null)
    const [drafts, setDrafts] = useState(null);
    const [articlesToUpdate, setArticlesToUpdate] = useState([]);
    const [openManageRow, setOpenManageRow] = useState(false);
    const [openArticleRow, setOpenArticleRow] = useState(null);
    const [openManageDraftRow, setOpenManageDraftRow] = useState(false);
    const [openDraftRow, setOpenDraftRow] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editArticle, setEditArticle] = useState({});
    const [alert, setAlert] = useState({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(initialArticles ? false : true);

    if (!initialArticles) {
        const fetchArticles = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-articles`);
            const fetchedArticles = await response.json();
            const responseDraft = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/drafts/get-drafts`);
            const fetchedDrafts = await responseDraft.json();
            setDrafts(fetchedDrafts);
            setArticles(fetchedArticles);
            setLoading(false);
        };
        fetchArticles();
    }

    const getArticlesToUpdate = (articles) => {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

        return articles.filter(article => {
            if (article.static) {
                return false;
            }
            const lastUpdated = article.updated ? new Date(article.updated) : new Date(article.created);
            return lastUpdated <= threeMonthsAgo;
        });
    };

    const handleApproveStaticArticle = async (id) => {
        const updated = new Date().getTime();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/manage-articles/update-manage-article`, { updated, id });
            if (response.status === 201) {
                // Remove the approved article from the articles list
                // setArticles(articles.filter(article => article._id !== id));
    
                // Remove the approved article from the articlesToUpdate list
                setArticlesToUpdate(articles.filter(article => article._id !== id));
                setAlert({ type: "success", message: "Successfully updated document." })
                setTimeout(() => {
                    setAlert({});
                }, 5000);
            } else {
                console.log(response.data.message);
                setError(response.data.message);
            }
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }
    

    const handleShowEditModal = (article) => {
        setEditArticle(article);
        setShowEditModal(true);
    }

    useEffect(() => {
        if (articles) {
            setArticlesToUpdate(getArticlesToUpdate(articles));
        }
    }, [articles]);

    return (
        <>
            <b>To-do</b>
            {loading ? <ListLoading /> :
                <>
                    {articlesToUpdate.length ?



                        <TableContainer>
                            {alert.message && <Alert severity={alert.type}>{alert.message}</Alert>}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell><b>manage type</b></TableCell>
                                        <TableCell><b>collection</b></TableCell>
                                        <TableCell><b>quantity</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                variant="soft"
                                                color="neutral"
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpenManageRow(!openManageRow)}
                                            >
                                                {openManageRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>

                                        </TableCell>
                                        <TableCell>update articles</TableCell>
                                        <TableCell>articles</TableCell>
                                        <TableCell>{articlesToUpdate.length}</TableCell>
                                    </TableRow>
                                    
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                            <Collapse in={openManageRow} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 2 }}>
                                                    <Table size="small" aria-label="collapsible content">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell />
                                                                <TableCell><b>#</b></TableCell>
                                                                <TableCell><b>Title</b></TableCell>
                                                                <TableCell><b>Last Updated</b></TableCell>
                                                                <TableCell><b>Actions</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {articlesToUpdate.map((article, index) => (
                                                                <>
                                                                    <TableRow key={article._id}>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                variant="soft"
                                                                                color="neutral"
                                                                                aria-label="expand row"
                                                                                size="small"
                                                                                onClick={() => setOpenArticleRow(openArticleRow === index ? null : index)}
                                                                            >
                                                                                {openArticleRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell>{index + 1}</TableCell>
                                                                        <TableCell>{article.title}</TableCell>
                                                                        <TableCell>{new Date(article.updated).toLocaleDateString()}</TableCell>
                                                                        <TableCell>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item><IconButton color="success" onClick={() => handleApproveStaticArticle(article._id)}><CheckCircleOutlineIcon /></IconButton></Grid>
                                                                                <Grid item><IconButton color="neutral" onClick={() => handleShowEditModal(article)}><EditIcon /></IconButton></Grid>
                                                                            </Grid>
                                                                        </TableCell>


                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                                                            <Collapse in={openArticleRow === index} timeout="auto" unmountOnExit>
                                                                                <Box sx={{ margin: 2 }}>
                                                                                    <Table size="small" aria-label="collapsible content">
                                                                                        <TableBody>
                                                                                            <TableRowWithCell heading="ID" data={article._id} />
                                                                                            <TableRowWithCell heading="Title" data={article.title} />
                                                                                            <TableRowWithCell heading="Content" data={article.content} />
                                                                                            <TableRowWithCell heading="Source" data={<Link color="neutral" href={article.source}><Typography level="body3">{article.source ?? "null"}</Typography></Link>} />
                                                                                            <TableRowWithCell heading="Created" data={article.created ? new Date(article.created).toLocaleDateString() : "null"} />
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Box>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell>
                                                                    
                                                                </TableCell>
                                                            </TableRow>

                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                variant="soft"
                                                color="neutral"
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpenManageDraftRow(!openManageDraftRow)}
                                            >
                                                {openManageDraftRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>

                                        </TableCell>
                                        <TableCell>handle drafts</TableCell>
                                        <TableCell>drafts</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        : <p>Nothing to manage.</p>
                    }
                </>
            }


            <EditArticleModal showEditModal={showEditModal} setShowEditModal={setShowEditModal} article={editArticle} setArticles={setArticles} type="articles" />

        </>
    )
}

export default ManageList;