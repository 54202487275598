import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

import { Button } from "@mui/joy";
import { Box, Grid, Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, TableFooter, TablePagination, IconButton } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TableRowWithCell from "./TableRowWithCell";

const UserList = ({ users: users, setUsers: setUsers }) => {
    const [openRow, setOpenRow] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertError, setAlertError] = useState("");

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sendPasswordReset = async (email) => {
        try {
            if (!email) {
                setAlertError(`Error sending password reset. User has no email.`);
                setTimeout(() => {
                    setAlertError("");
                }, 5000);
                return;
            };
            
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/password-reset/send-reset`, { email });
            console.log(response.data);
            setAlertMessage(`Successfully sent password reset link to: ${email}`);
            setTimeout(() => {
                setAlertMessage("");
            }, 10000);
        } catch (error) {
            console.error("Error sending password reset email", error);
            setAlertError(`Error sending password reset email: ${error}`);
            setTimeout(() => {
                setAlertError("");
            }, 10000);
        }
    };

    return (
        <TableContainer component={Paper} sx={{ maxWidth: 600 }}>
            {alertMessage && <Alert>{alertMessage}</Alert>}
            {alertError && <Alert severity="error">{alertError}</Alert>}
            <Table stickyHeader aria-label="collapsible table" size="small">
                <TableHead>
                    <TableRow style={{ zIndex: 100 }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={7}
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                    <TableRow style={{ zIndex: 100 }}>
                        <TableCell />
                        <TableCell>#</TableCell>
                        <TableCell>username</TableCell>
                        <TableCell>role</TableCell>
                        <TableCell>email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : users
                    ).map((user, index) => {
                        return (
                            <React.Fragment key={user._id}>
                                <TableRow>
                                    <TableCell>
                                        <IconButton
                                            variant="soft"
                                            color="neutral"
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenRow(openRow === index ? -1 : index)}
                                        >
                                            {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{user.username ?? "null"}</TableCell>
                                    <TableCell>{user.role ?? "null"}</TableCell>
                                    <TableCell>{user.email ?? "null"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        style={{ paddingBottom: 0, paddingTop: 0 }}
                                        colSpan={7}
                                    >
                                        <Collapse
                                            in={openRow === index}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <Box sx={{ margin: 2 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Table size="small" aria-label="collapsible content">
                                                            <TableHead>Details</TableHead>
                                                            <TableBody>
                                                                <TableRowWithCell heading="User ID" data={user._id ?? "null"} />
                                                                <TableRowWithCell heading="Email" data={user.email ?? "null"} />
                                                                <TableRowWithCell heading="Username" data={user.username ?? "null"} />
                                                                <TableRowWithCell heading="Role" data={user.role ?? "null"} />
                                                                <TableRowWithCell heading="First name" data={user.fName ?? "null"} />
                                                                <TableRowWithCell heading="Last name" data={user.lName ?? "null"} />
                                                                <TableRowWithCell heading="Created At" data={new Date(user.createdAt).toLocaleDateString() ?? "null"} />
                                                                <TableRowWithCell heading="Last Password Reset" data={user.lastPasswordReset ? new Date(user.lastPasswordReset).toLocaleString() : "null"} />
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                    <Grid item>
                                                        <TableHead>Actions</TableHead>
                                                        <Button style={{ width: "100px" }} variant="soft" color="neutral">Edit</Button>
                                                        <br />
                                                        <Button
                                                            style={{ width: "100px" }}
                                                            variant="solid"
                                                            color="warning"
                                                            onClick={() => sendPasswordReset(user.email)}
                                                        >
                                                            Reset Password
                                                        </Button>
                                                        <br />
                                                        <Button style={{ width: "100px" }} variant="solid" color="danger" onClick={() => setShowDeleteModal(true)}>Delete</Button>
                                                        <ConfirmDeleteModal removalObject={user.email} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deletePath="auth/users/delete-user" id={user._id} setArray={setUsers} array={users} />
                                                    </Grid>
                                                </Grid>



                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={7}
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default UserList;