import React from "react";
import { Skeleton, Stack } from "@mui/material";

const ListLoading = () => {
    return (
        <>
            <br/>
            <Stack spacing={1}>
                <Skeleton variant="rounded" animation="pulse" width={200} height={40} />
                <Skeleton variant="rounded" animation="pulse" width={300} />
                <Skeleton variant="rounded" animation="pulse" width={300} />
            </Stack>
        </>
    )
}

export default ListLoading;