import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const ProtectedRoute = ({ roles = [], component: Component }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const isAllowed = () => {
        if (!token) return false;

        try {
            const decoded = jwt_decode(token);

            if (roles.length) {
                return roles.includes(decoded.role);
            }

            return true;
        } catch (error) {
            return false;
        }
    };

    React.useEffect(() => {
        if (!isAllowed()) {
            navigate('/login', { replace: true });
        }
    }, [navigate]);

    //   return <Outlet />;
    return isAllowed() ? <Component /> : null;

};

export default ProtectedRoute;
