import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f6e8d',
        },
        secondary: {
            main: '#5f99bc',
        },
        error: {
            main: '#ff684b',
        },
        warning: {
            main: '#ffa74b'
        },
        success: {
            main: '#6eb738'
        }
    }
    // ,
    // typography: {
    //     fontFamily: '"Public Sans", sans-serif', 
    // },
});

export default theme;