import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ListLoading from '../../../../_components/Loading/ListLoading';




const AnalyticsReport = () => {
    const [reportData, setReportData] = useState(null);
    ChartJS.register(CategoryScale);
    ChartJS.register(LinearScale);
    ChartJS.register(PointElement);
    ChartJS.register(LineElement);
    ChartJS.register(Title);
    ChartJS.register(Tooltip);
    ChartJS.register(Legend);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/analytics`);
                const sortedData = response.data.data.sort((a, b) => new Date(a.date) - new Date(b.date));
                setReportData({ data: sortedData });
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        };

        fetchData();
    }, []);

    if (!reportData) {
        return <ListLoading />;
    }

    const chartData = {
        labels: reportData.data.map((data) => data.date),
        datasets: [
            {
                label: 'New Users',
                data: reportData.data.map((data) => data.newUsers),
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
            {
                label: 'Total Users',
                data: reportData.data.map((data) => data.totalUsers),
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
            },
        ],
    };
    const eventData = {
        labels: reportData.data.map((data) => data.eventName),
        datasets: [
            {
                label: 'Event Count',
                data: reportData.data.map((data) => data.eventCount),
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
            {
                label: 'Event Count Per User',
                data: reportData.data.map((data) => data.eventCountPerUser),
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    return (
        <div>
            <Line data={chartData} />
            {/* <Line data={eventData} /> */}
        </div>
    );
};

export default AnalyticsReport;
