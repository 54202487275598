import React, { useState, useEffect } from "react";
import axios from "axios";
import ObjectID from 'bson-objectid';

import { Button, Typography, Modal, ModalClose, ModalDialog, Input, FormControl, FormLabel, Textarea, Grid, Switch, AspectRatio } from "@mui/joy";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, Divider } from "@mui/material";

import ConfirmDeleteModal from "./ConfirmDeleteModal";
import DeleteIcon from '@mui/icons-material/Delete';

import SnackbarAlert from "../Alert";

const CategoryModal = ({ showCategoryModal, setShowCategoryModal }) => {
    const [newCategoryName, setNewCategoryName] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" });
    const [categories, setCategories] = useState([]);

    const handleCreateCategory = async () => {
        try {
            if (newCategoryName) {
                const newCategory = {
                    _id: new ObjectID().toString(),
                    name: newCategoryName,
                    createdAt: new Date().getTime(),
                    updatedAt: null
                }
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/manage-articles/create-category`, { newCategory })
                if (response.status === 201) {
                    setCategories([...categories, newCategory]);
                    setAlert({ open: true, severity: "success", message: "The category was successfully added." });
                } else {
                    setAlert({ open: true, severity: "error", message: response.data.message });
                }
                setNewCategoryName("");
            } else { return }
        } catch (error) {
            console.log(error);
            setAlert({ open: true, severity: "error", message: "An error occurred while adding the category." });
        }
    }

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-categories`);
            const fetchedCategories = await response.json();
            setCategories(fetchedCategories);
        } catch (error) {
            setAlert({ open: true, severity: "error", message: `Error fetching categories. ${error}` });
        }
    }

    const handleDeleteClick = (cat) => {
        setCategoryToDelete(cat);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (showCategoryModal) {
            fetchCategories();
        }
    }, [showCategoryModal]);

    return (
        <>
            <Modal
                open={showCategoryModal}
                onClose={() => setShowCategoryModal(false)}
            >
                <ModalDialog>
                    <ModalClose />
                    <Typography level="h4">Categories</Typography>
                    <FormControl>
                        <FormLabel>Add Category</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Input required placeholder="största" value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} />
                            </Grid>
                            <Grid item>
                                <Button color="success" variant="soft" onClick={handleCreateCategory}>+</Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <Divider variant="middle" sx={{ margin: 3 }} />
                    <FormLabel>Category List</FormLabel>
                    <TableContainer sx={{ maxHeight: 300 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>id</b></TableCell>
                                    <TableCell><b>name</b></TableCell>
                                    <TableCell><b>created</b></TableCell>
                                    <TableCell><b>updated</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categories.map((cat, index) => {
                                    return (
                                        <TableRow key={cat._id}>
                                            <TableCell>{index}</TableCell>
                                            <TableCell>...{cat._id.slice(18, 24)}</TableCell>
                                            <TableCell>{cat.name}</TableCell>
                                            <TableCell>{new Date(cat.createdAt).toLocaleDateString()}</TableCell>
                                            <TableCell>{cat.updatedAt ?? "null"}</TableCell>
                                            <TableCell><Button color="transparent" onClick={() => { handleDeleteClick(cat) }}><DeleteIcon sx={{ color: 'red' }} /></Button></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalDialog>
            </Modal>
            <ConfirmDeleteModal removalObject={categoryToDelete?.name} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deletePath="api/manage-articles/delete-category" id={categoryToDelete?._id} setArray={setCategories} array={categories} setAlert={setAlert} />
            <SnackbarAlert alert={alert} setAlert={setAlert} />
        </>
    )
}

export default CategoryModal;