import config from "../../config";
const url = `${config.apiUrl}/api/articles`;

const incrementRequestCount = async (articleId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/incrementRequestCount?id=${articleId}`, { method: 'PUT' });
        const updatedArticle = await response.json();
        // Handle the updated article if needed
    } catch (error) {
        console.error("Error incrementing request count:", error);
    }
};

export { incrementRequestCount };
