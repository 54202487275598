import React, { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import axios from "axios";
import ObjectID from 'bson-objectid';

import { Button, Link, Typography, Modal, ModalClose, ModalDialog, Input, FormControl, FormLabel, Textarea, Grid } from "@mui/joy";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, Snackbar, Alert, Divider } from "@mui/material";

import CategoryModal from "./CategoryModal";
import SnackbarAlert from "../Alert";

const CreateModal = ({ articles, setArticles, showCreateModal, setShowCreateModal, categories, setCategories, showCategoryModal, setShowCategoryModal }) => {
    const [title, setTitle] = useState("");
    const [altTitle, setAltTitle] = useState("");
    const [content, setContent] = useState("");
    const [source, setSource] = useState("");
    const [category, setCategory] = useState("");
    const [isStatic, setIsStatic] = useState(null);
    const [user, setUser] = useState({});
    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" });

    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);

    const getUser = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/users/get-user`, { decodedToken });
            setUser(response.data.user);
            // console.log(response.data.user);
        } catch (error) {
            setAlert({ open: true, severity: "error", message: `Error fetching user details. ${error}` });
        }
    }
    useEffect(() => {
        getUser();
    }, []);

    const handleCreateArticle = async () => {
        try {
            if (title) {
                const newArticle = {
                    _id: new ObjectID().toString(),
                    title, altTitle, content, source, category: category, static: isStatic, requestCount: 0, creator: user.username,
                    created: new Date().getTime(),
                    updater: null, updated: null, region: null, option: null, input: null, seeAlso: null, keyword: null
                };

                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/manage-articles/create-article`, { newArticle });
                if (response.status === 201) {
                    setShowCreateModal(false);
                    setArticles([...articles, newArticle]);
                    setAlert({ open: true, severity: "success", message: "The article was successfully created." });
                } else {
                    console.log(response.data.message);
                    setAlert({ open: true, severity: "error", message: `Error creating article. ${response.data.error}` });
                }
            } else { 
                setAlert({ open: true, severity: "error", message: `Title empty. Fill in title and try again.` });
                return;
            };
        } catch (error) {
            console.log(error);
            setAlert({ open: true, severity: "error", message: `Error creating article. ${error}` });
        }
    }

    const handleCreateDraft = async () => {
        try {
            if (title) {
                const newDraft = {
                    _id: new ObjectID().toString(),
                    title, altTitle, content, source, category: category, static: isStatic, requestCount: 0, creator: user.username,
                    created: new Date().getTime(),
                    updater: null, updated: null, region: null, option: null, input: null, seeAlso: null, keyword: null
                };

                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/drafts/create-draft`, { newDraft });
                if (response.status === 201) {
                    setShowCreateModal(false);
                    setArticles([...articles, newDraft]);
                    setAlert({ open: true, severity: "success", message: "The draft was successfully saved." });
                } else {
                    console.log(response.data.message);
                    setAlert({ open: true, severity: "error", message: `Error creating draft. ${response.data.error}` });
                }
            } else { 
                setAlert({ open: true, severity: "error", message: `Title empty. Fill in title and try again.` });
                return;
            };
        } catch (error) {
            console.log(error);
            setAlert({ open: true, severity: "error", message: `Error creating article. ${error}` });
        }
    }

    return (
        <>
            <CategoryModal setShowCategoryModal={setShowCategoryModal} showCategoryModal={showCategoryModal} />
            <Modal
                open={showCreateModal}
                onClose={() => setShowCreateModal(false)}
            >
                <ModalDialog sx={{ overflowY: "scroll" }}>
                    <ModalClose />
                    <Typography level="h4">Create Article</Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography>Content</Typography>
                            <FormControl sx={{ minWidth: 300 }}>
                                <FormLabel>Title</FormLabel>
                                <Textarea placeholder="Vilken är Sveriges största stad?" onChange={(e) => setTitle(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Alternative Title</FormLabel>
                                <Input placeholder="Sveriges största stad" onChange={(e) => setAltTitle(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Content</FormLabel>
                                <Textarea minRows={3} placeholder="Den största staden i Sverige är..." onChange={(e) => setContent(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Source</FormLabel>
                                <Input placeholder="https://snabbafakta.nu/exempel" onChange={(e) => setSource(e.target.value)} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Typography>Options</Typography>
                            <FormControl>
                                <FormLabel>Creator</FormLabel>
                                <Input disabled value={user.username} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Hidden</FormLabel>
                                <Input disabled value="WIP" />
                            </FormControl>
                            <Grid container sx={{ paddingLeft: 0 }}>
                                <Grid item>
                                    <FormControl>
                                        <FormLabel>Category (<Link onClick={() => setShowCategoryModal(true)}>manage</Link>)</FormLabel>
                                        <Select sx={{ minWidth: 200 }} placeholder="Choose category..." onChange={(e) => { setCategory(e.target.value); }}>
                                            {categories.map((cat, index) => {
                                                return (
                                                    <MenuItem key={index} variant="soft" color="neutral" value={cat.name}>{cat.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <FormLabel>Static</FormLabel>
                                        <Select defaultValue={true} onChange={(e) => setIsStatic(e.target.value)}>
                                            <MenuItem key="true" value={true}>true</MenuItem>
                                            <MenuItem key="false" value={false}>false</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1} sx={{ paddingLeft: 0 }}>
                                <Grid item>
                                    <Button variant="soft" color="success" onClick={handleCreateArticle}>Create</Button>

                                </Grid>
                                <Grid item>
                                    <Button variant="soft" color="warning" onClick={handleCreateDraft}>Save Draft</Button>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ModalDialog>
            </Modal>
            <SnackbarAlert alert={alert} setAlert={setAlert} />
        </>

    )
}

export default CreateModal;