import React from "react";
import { List, ListItem, Link, Typography, Stack, Divider } from "@mui/joy"
import { Box } from "@mui/material";
import Logo from "../Logo";

const Footer = () => {

    const year = () => {
        return new Date().getFullYear();
    }

    return (
        <Box
            component="footer"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
            }}
        >
            <Stack spacing={2} direction="row">
                <Box
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center"
                    }}
                >
                    <Logo />
                </Box>
                <Divider orientation="vertical" />
                <Box>
                    <List>
                        <ListItem><Link href="/"><Typography>Hem</Typography></Link></ListItem>
                        <ListItem><Link href="/"><Typography>Artiklar</Typography></Link></ListItem>
                        <ListItem><Link href="/about"><Typography>Om oss</Typography></Link></ListItem>
                    </List>
                </Box>
            </Stack >
            <Typography level="body3">Copyright &#169; SNABBAFAKTA {year()}</Typography>
            <Typography level="body5">v2.0.<Link href="/login" style={{ textDecoration: "none", color: "inherit" }}>0</Link></Typography>
        </Box >

    )
}

export default Footer;