import React from "react";
import { useNavigate } from 'react-router-dom';

import ShuffleIcon from '@mui/icons-material/Shuffle';
import { IconButton } from "@mui/joy";

const RandomButton = ({articles}) => {
    const navigate = useNavigate();

    const handleRandomRedirect = (event) => {
        event.preventDefault();
        const randomNumber = Math.floor(Math.random() * articles.length);
        const selectedArticle = articles[randomNumber]
        if (selectedArticle) {
            navigate(`/article/${encodeURIComponent(selectedArticle.title)}`, { state: { article: selectedArticle } });
        }
    };

    return (
        <IconButton
            sx={{ width: 48, height: 48 }}
            onClick={handleRandomRedirect}
            variant="soft" color="neutral"><ShuffleIcon /></IconButton>
    )
}

export default RandomButton;