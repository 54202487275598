import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { FormControl, FormLabel, Autocomplete, IconButton } from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Fade from '@mui/material/Fade';
import RandomButton from "../RandomButton";

import "./index.css";

const SearchInput = ({ articles, setSearch, search }) => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [typing, setTyping] = useState(false);

    const handleSearch = (event) => {
        setSearch("");
        setShowAlert(false); // Reset the alert state
        if (!search) {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage("Försök igen.")
            }, 2000);
            return;
        }
        event.preventDefault();
        const selectedArticle = articles.find((article) => article.title === search);
        if (selectedArticle) {
            navigate(`/article/${encodeURIComponent(selectedArticle.title)}`, { state: { article: selectedArticle } });
        } else {
            navigate("/search", { state: { searchOld: search } });
        }
    };

    const customFilter = (options, state) => {
        const filtered = options.filter((option) => {
            const inputValue = state.inputValue.toLowerCase();
            const title = option.title.toLowerCase();
            return title.includes(inputValue);
        });

        if (state.inputValue !== '') {
            filtered.push({ title: state.inputValue });
        }

        return filtered;
    };

    const setRandomPlaceholder = () => {
        if (typing) return;  // if already typing, do nothing
        setTyping(true);
        const ran = Math.floor(Math.random() * articles.length);
        const title = articles[ran]?.title;
        if (!title) return; // Check that title is not undefined or empty
        let newPlaceholder = '';
        let index = 0;
        const interval = setInterval(() => {
            newPlaceholder += title.charAt(index);
            setPlaceholder(newPlaceholder);
            index++;
            if (index === title.length) {
                clearInterval(interval);
                setTyping(false);
            }
        }, 40);
    };

    useEffect(() => {
        if (!typing) {
            setRandomPlaceholder();
        }
    }, []);



    return (
        <>
            <FormControl>
                <div className="search-btn-container">
                    <Autocomplete
                        size="lg"
                        placeholder={placeholder ?? "Vilken är Sveriges Största Stad?"}
                        variant="soft"
                        getOptionLabel={(option) => option.title}
                        options={articles}
                        freeSolo
                        inputValue={search}
                        onInputChange={(event, newInputValue) => {
                            setSearch(newInputValue);
                        }}
                        filterOptions={customFilter}
                        sx={{
                            width: "60vw",
                        }}
                    />

                    <IconButton onClick={handleSearch} sx={{ width: 48, height: 48 }} color="neutral" variant="soft"><SearchIcon /></IconButton>
                    <RandomButton articles={articles} />
                </div>
            </FormControl>
            <Fade in={showAlert} timeout={500}>
                <div>
                    {showAlert && <Alert severity="warning">{alertMessage}</Alert>}
                </div>
            </Fade>
        </>
    )
}

export default SearchInput;
