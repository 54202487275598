import React from "react";
import axios from "axios";
import { Modal, ModalClose, ModalDialog, Typography, Button } from "@mui/joy";
import { Grid } from "@mui/material";

const ConfirmDeleteModal = ({ removalObject, showDeleteModal, setShowDeleteModal, deletePath, id, setArray, array, setAlert }) => {

    const handleDelete = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/${deletePath}`, { id: id });
            if (response.status === 200) {
                const updatedArray = array.filter(item => item._id !== id);
                setArray(updatedArray);
                setAlert({ open: true, severity: "success", message: `${removalObject} was successfully deleted.` });
            } else {
                console.log(response.data.message);
                setAlert({ open: true, severity: "error", message: response.data.message });
            }
        } catch (error) {
            console.log(error);
            setAlert({ open: true, severity: "error", message: "An error occurred while deleting the category." });
        }
        setShowDeleteModal(false);
    }

    return (
        <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
        >
            <ModalDialog>
                <ModalClose />
                <Typography level="h4">Confirm removal</Typography>
                <Typography>Are you sure you want to delete <b>{removalObject}</b></Typography>
                <Grid container >
                    <Button item onClick={handleDelete}>Confirm</Button>
                    <Button item onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                </Grid>

            </ModalDialog>
        </Modal>
    )
}

export default ConfirmDeleteModal;