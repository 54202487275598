import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from "axios";
import lodash from "lodash";

import { AppBar, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Toolbar, Typography, IconButton, Link } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import UserIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CodeIcon from '@mui/icons-material/Code';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import FlagIcon from '@mui/icons-material/Flag';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import TaskIcon from '@mui/icons-material/Task';

import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../../../_components/Logo";


const drawerWidth = 240;

const MenuItem = (props) => {
    const location = useLocation();

    return (
        <Link href={props.href} style={{ textDecoration: "none" }}>
            <ListItem disablePadding
                secondaryAction={
                    <ChevronRightIcon edge="end" />
                }

            >
                <ListItemButton>
                    {/* <ListItemAvatar><Avatar>{props.icon}</Avatar></ListItemAvatar> */}
                    <ListItemIcon>{props.icon}</ListItemIcon>
                    <ListItemText
                        primary={<Typography style={location.pathname === props.href ? { color: "#bcbcbc" } : {}}>{props.primaryText}</Typography>} secondary={<Typography variant="body2">{props.secondaryText}</Typography>} />
                </ListItemButton>
            </ListItem>
        </Link>
    )
}

const Nav = (props) => {
    const { window } = props;
    const navigate = useNavigate();

    const [mobileOpen, setMobileOpen] = useState(false);
    const [user, setUser] = useState({});
    const [error, setError] = useState("");

    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);

    const getUser = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/users/get-user`, { decodedToken });
            setUser(response.data.user);
            // console.log(response.data.user);
        } catch (error) {
            setError("Error fetching user details")
        }
    }
    useEffect(() => {
        getUser()
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const drawer = (
        <div>
            <Toolbar><Typography variant="subtitle2">SNABBAFAKTA.nu</Typography></Toolbar>
            <Box display="flex" flexDirection="column" height="90vh" justifyContent="space-between">

                {/* <Divider /> */}
                <List dense>
                    <MenuItem primaryText="Dashboard" icon={<HomeIcon />} href="/admin/dashboard" />
                    <MenuItem primaryText="Articles" icon={<ArticleIcon />} href="/admin/articles" />
                    {decodedToken.role === "Admin+" ? <MenuItem primaryText="Users" icon={<GroupIcon />} href="/admin/users" /> : null}
                    {decodedToken.role === "Admin" || decodedToken.role === "Admin+" ?
                        <>
                            <MenuItem primaryText="API" icon={<CodeIcon />} href="/admin/api" />
                            <MenuItem primaryText="Game" icon={<VideogameAssetIcon />} href="/admin/game" />
                            <MenuItem primaryText="Tasks" icon={<TaskIcon />} href="/admin/tasks" />
                        </> : null}

                    <MenuItem primaryText="Reports" icon={<FlagIcon />} href="/admin/reports" />
                </List>
                {/* <Divider /> */}
                <List dense>
                    <Divider />
                    <MenuItem primaryText="snabbafakta.nu" icon={<ElectricBoltIcon />} secondaryText="redirect to home" href="/" />
                    <Divider />
                    <MenuItem primaryText={lodash.capitalize(user.fName) + " " + lodash.capitalize(user.lName)} icon={<UserIcon />} secondaryText={decodedToken.role} href="/admin/profile" />
                    <ListItem disablePadding
                        secondaryAction={
                            <ChevronRightIcon edge="end" />
                        }
                    >
                        <ListItemButton onClick={handleLogout}>
                            {/* <ListItemAvatar><Avatar><LogoutIcon /></Avatar></ListItemAvatar> */}
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText primary={<Typography>Logout</Typography>}></ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </div>
    );
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                {/* <Toolbar sx={{ backgroundColor: "#ff5f0091" }}> */}
                <Toolbar sx={{ backgroundColor: "#00a8ffc2" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <img src="/img/snabbafakta_lang.png" /> */}
                    <Logo />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "#272727" },

                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "#272727" },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </>


    )
}

export default Nav;