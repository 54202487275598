import React, { useState } from "react";
import { Modal, ModalClose, ModalDialog } from "@mui/joy";

import Article from "../../../article/Article";

const PreviewModal = ({ showPreviewModal, setShowPreviewModal, article }) => {
    return (
        <Modal
            open={showPreviewModal}
            onClose={() => setShowPreviewModal(false)}
        >
            <ModalDialog style={{width: "95vw", overflowX: "scroll"}}>
                <ModalClose />
                <Article article={article} style={{ transform: "scale(0.6)" }} />
            </ModalDialog>
        </Modal>
    )
}

export default PreviewModal;