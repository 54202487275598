import React, { useState } from "react";
import ObjectID from 'bson-objectid';
import axios from "axios";

import { Modal, ModalClose, ModalDialog, Input, Button, Typography } from "@mui/joy";
import { Alert, Grid, FormControl, FormLabel } from "@mui/material";

const RegisterModal = ({
    showRegisterModal: showRegisterModal,
    setShowRegisterModal: setShowRegisterModal,
    setUsers: setUsers,
    setAlertError: setAlertError,
    alertError: alertError
}) => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('Editor');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = {
                _id: new ObjectID().toString(),
                email: email,
                username: username,
                fName: fName,
                lName: lName,
                password: password,
                role: role,
                createdAt: new Date().getTime()
            }
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register`, { user });
            if (response.status === 201) {
                setShowRegisterModal(false);
                setUsers((prevUsers) => [...prevUsers, user])
            } else {
                console.log(response.data.message);
                setError(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
                setAlertError(error.response.data.message);
                setTimeout(() => {
                    setAlertError("");
                }, 5000);
            } else {
                setError('Error registering user');
            }
        }
    };

    return (
        <Modal
            open={showRegisterModal}
            onClose={() => setShowRegisterModal(false)}
        >
            <ModalDialog>
                <ModalClose />
                <Typography level="h5">Register user</Typography>
                {alertError && <Alert severity="error">{alertError}</Alert>}
                <form onSubmit={handleSubmit}>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="ex@example.com" />
                        <FormLabel>Username</FormLabel>
                        <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required placeholder="jd-admin92" />
                        <Grid container>
                            <Grid item>
                                <>
                                    <FormLabel>First name</FormLabel>
                                    <Input type="text" value={fName} onChange={(e) => setFName(e.target.value)} required placeholder="John" />
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder="********" />
                                </>
                            </Grid>
                            <Grid item>
                                <>
                                    <FormLabel>Last name</FormLabel>
                                    <Input type="text" value={lName} onChange={(e) => setLName(e.target.value)} required placeholder="Doe" />

                                </>
                            </Grid>
                        </Grid>
                        <FormLabel>Role</FormLabel>
                        <select value={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="Editor">Editor</option>
                            <option value="Admin">Admin</option>
                            <option value="Admin+">Admin+</option>
                        </select>
                        <Button type="submit">Register</Button>
                    </FormControl>
                </form>

            </ModalDialog>
        </Modal>
    )
}

export default RegisterModal;