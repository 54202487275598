import React from 'react';
import ReactDOM from 'react-dom/client';
import WebRoutes from "./routes";
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './_components/_utils/theme';


// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
      <WebRoutes />
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
