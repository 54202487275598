import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

const Logo = () => {
    const navigate = useNavigate();
    return (
        <>
            <Link 
                href="/"
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    textDecoration: "none"
                }}
            >
                <img alt="" src="/img/flash.png" style={{ height: 50 }} />
                <i className="logoText" style={{ fontSize: "1.5rem" }}>SNABBAFAKTA</i>
                {/* <img src="/img/snabbafakta_lang_svart.svg" style={{height: 80}} /> */}
                {/* <Typography level="h3" color="#555454" component="div">SNABBAFAKTA.nu</Typography> */}
            </Link>

        </>
    )
}

export default Logo;