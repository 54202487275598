//REACT
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//MUI COMPONENTS
import { AppBar, Toolbar, useScrollTrigger, Slide, Box, Grid, Tab, Tabs, IconButton, Hidden, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Autocomplete } from "@mui/joy";

import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';

//COMPONENTS
import Logo from "../Logo";

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Navbar = (props) => {
    const [tab, setTab] = useState();
    const [articles, setArticles] = useState({});
    const [search, setSearch] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [placeholder, setPlaceholder] = useState("");
    const [typing, setTyping] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const tabs = [
        { label: "Hem", href: "/" },
        { label: "Artiklar", href: "/articles" },
        { label: "Om oss", href: "/about" }
    ];

    const currentTabIndex = tabs.findIndex(tab => tab.href === location.pathname);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const LinkTab = (props) => {
        return (
            <Tab
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                    navigate(props.href);
                }}
                {...props}
            />
        );
    }

    const handleShowSearch = () => {
        setShowSearch(!showSearch);
    };

    const handleSearch = (event) => {
        setSearch("");

        event.preventDefault();
        const selectedArticle = articles.find((article) => article.title === search);
        if (selectedArticle) {
            navigate(`/article/${encodeURIComponent(selectedArticle.title)}`, { state: { article: selectedArticle } });
        } else {
            navigate("/search", { state: { searchOld: search } });
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-articles`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const fetchedArticles = await response.json();
            setArticles(fetchedArticles);
        } catch (error) {
            console.error("Error fetching articles:", error);
        }
    }

    const customFilter = (options, state) => {
        const filtered = options.filter((option) => {
            const inputValue = state.inputValue.toLowerCase();
            const title = option.title.toLowerCase();
            return title.includes(inputValue);
        });

        if (state.inputValue !== '') {
            filtered.push({ title: state.inputValue });
        }

        return filtered;
    };

    const setRandomPlaceholder = () => {
        if (typing) return;  // if already typing, do nothing
        setTyping(true);
        const ran = Math.floor(Math.random() * articles.length);
        const title = articles[ran]?.title;
        if (!title) return; // Check that title is not undefined or empty
        let newPlaceholder = '';
        let index = 0;
        const interval = setInterval(() => {
            newPlaceholder += title.charAt(index);
            setPlaceholder(newPlaceholder);
            index++;
            if (index === title.length) {
                clearInterval(interval);
                setTyping(false);
            }
        }, 40);
    };

    useEffect(() => {
        fetchArticles();
        setRandomPlaceholder();
    }, []);

    const renderDrawerList = () => (
        <>
            <List sx={{marginTop: "10vh"}}>
                {tabs.map((tab, index) => (
                    <ListItem button key={index} onClick={() => { navigate(tab.href); setDrawerOpen(false); }}>
                        <ListItemText sx={{ color: "#fff" }} primary={tab.label} />
                    </ListItem>
                ))}
            </List>
        </>

    );

    return (
        <HideOnScroll {...props}>
            <AppBar style={{ boxShadow: "none" }}>
                <Toolbar sx={{justifyContent: "center"}}>
                    <Box className="navBox" display="flex" justifyContent="space-between" width="100%">
                        <Box className="menuButtonBox">
                            <Hidden mdUp implementation="css">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleDrawer}
                                    sx={{ zIndex: 100 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    anchor="left"
                                    open={drawerOpen}
                                    onClose={toggleDrawer}
                                    PaperProps={{
                                        style: {
                                            width: "35vw",
                                            backgroundColor: "#648399"
                                        }
                                    }}
                                >
                                    {renderDrawerList()}
                                </Drawer>
                            </Hidden>
                        </Box>
                        <Box className="logoBox">
                            <Logo />
                        </Box>
                        <Box>
                            <Hidden smDown implementation="css">
                                <Tabs
                                    onChange={handleChangeTab}
                                    value={currentTabIndex !== -1 ? currentTabIndex : false}
                                    TabIndicatorProps={{
                                        sx: {
                                            top: 0,
                                            borderRadius: "0 0 4px 4px",
                                            height: 5
                                        }
                                    }}
                                >
                                    {tabs.map((tab, index) => (
                                        <LinkTab key={index} style={{ height: "65px" }} label={tab.label} href={tab.href} />
                                    ))}
                                    <IconButton size="large" onClick={handleShowSearch}><SearchIcon fontSize="large" /></IconButton>
                                </Tabs>
                            </Hidden>
                        </Box>

                    </Box>
                    {showSearch &&
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Autocomplete
                                placeholder="Vilken är Sveriges största stad?"
                                variant="soft"
                                getOptionLabel={(option) => option.title}
                                options={articles}
                                freeSolo
                                inputValue={search}
                                onInputChange={(event, newInputValue) => {
                                    setSearch(newInputValue);
                                }}
                                filterOptions={customFilter}
                                sx={{width: "50vw"}}
                            />
                            <IconButton onClick={handleSearch} sx={{ width: 48, height: 48 }}><SearchIcon /></IconButton>
                        </Box>
                    }

                </Toolbar>


            </AppBar>
        </HideOnScroll >
    )
}

export default Navbar;