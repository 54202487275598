import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//GENERAL
import Home from './_pages/home/Home';
import About from './_pages/about/About';
import Report from './_pages/report/Report';
import Article from './_pages/article/Article';
import ArticleList from "./_pages/article/ArticleList"
import Search from './_pages/search/Search';
import NotFound from './_pages/notfound/NotFound';

//ADMIN
import ProtectedRoute from './_pages/admin/_utils/ProtectedRoute';
import Admin from './_pages/admin/Admin';
import Login from './_pages/admin/Login';
import Register from './_pages/admin/Register';
import Dashboard from './_pages/admin/_pages/Dashboard';
import Profile from "./_pages/admin/_pages/Profile";
import Users from "./_pages/admin/_pages/Users";
import ResetPassword from './_pages/admin/_pages/ResetPassword';
import AnalyticsReport from './_pages/admin/_components/charts/AnalyticsReport';
import Articles from './_pages/admin/_pages/Articles';



// import Admin from './components/Admin';
// import AdminPlus from './components/AdminPlus';

function WebRoutes() {
    return (
        <Router>
            <Routes>
                {/* GENERAL */}
                <Route path="/" exact element={<Home />} />
                <Route path="/article/:title" element={<Article />} />
                <Route path="/search" element={<Search />} />
                <Route path="/about" element={<About />} />
                <Route path="/report" element={<Report />} />
                <Route path="/login" element={<Login />} />
                <Route path="/articles" element={<ArticleList />} />
                <Route path="*" element={<NotFound />} />
                {/* ADMIN */}
                {/* <Route path="/register" element={<Register />} /> */}
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/analytics-report" element={<AnalyticsReport />} />;

                <Route path="/admin/dashboard" element={<ProtectedRoute roles={['Admin', 'Admin+']} component={Dashboard} />} />
                <Route path="/admin/articles" element={<ProtectedRoute roles={['Editor', 'Admin', 'Admin+']} component={Articles} />} />
                <Route path="/admin/users" element={<ProtectedRoute roles={['Admin+']} component={Users} />} />
                <Route path="/admin/api" element={<ProtectedRoute roles={['Admin', 'Admin+']} component={Dashboard} />} />
                <Route path="/admin/game" element={<ProtectedRoute roles={['Admin', 'Admin+']} component={Dashboard} />} />
                <Route path="/admin/reports" element={<ProtectedRoute roles={['Editor', 'Admin', 'Admin+']} component={Dashboard} />} />
                <Route path="/admin/profile" element={<ProtectedRoute roles={['Editor', 'Admin', 'Admin+']} component={Profile} />} />
                <Route path="/admin/tasks" element={<ProtectedRoute roles={['Admin', 'Admin+']} component={Dashboard} />} />

                <Route path="/admin/users/register" element={<ProtectedRoute roles={["Admin+"]} component={Register} />} />
                {/* <Route path="/adminplus" element={<ProtectedRoute roles={["Admin+"]} component={Admin} />}/> */}
                <Route path="/editor/dashboard" element={<ProtectedRoute roles={["Editor"]} component={Dashboard} />} />
                {/* WHEN EDITORS ADD ARTICLES, IT SHOULD BE ADDED TO DRAFTS, NOT ARTCILES. THEN ADMINS SHOULD ATTESTERA THE DRAFTS TO APPROVE OR NOT. */}
                {/* EDITORS SHOULD SEE THE ARTICLES THAT ARE WAITING FOR APPROVAL */}
            </Routes>
        </Router>
    );
}

export default WebRoutes;
