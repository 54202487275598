import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from 'jwt-decode';

import { Button, Link, Typography, Modal, ModalClose, ModalDialog, Input, FormControl, FormLabel, Textarea, Grid, Switch, AspectRatio } from "@mui/joy";
import { Select, MenuItem } from "@mui/material";

import CategoryModal from "./CategoryModal";
import SnackbarAlert from "../Alert";

const EditArticleModal = ({ showEditModal, setShowEditModal, article, setArticles, type }) => {
    const [title, setTitle] = useState(article.title ?? null);
    const [altTitle, setAltTitle] = useState(article.altTitle ?? null);
    const [content, setContent] = useState(article.content ?? null);
    const [source, setSource] = useState(article.source ?? null);
    const [category, setCategory] = useState(article.category ?? null);
    const [isStatic, setIsStatic] = useState(article.static ?? null);
    const [user, setUser] = useState({});

    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" });
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [categories, setCategories] = useState([]);

    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);

    const fetchCategories = async () => {
        const responseCategories = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/articles/get-categories`);
        const fetchedCategories = await responseCategories.json();
        setCategories(fetchedCategories);
    }

    const getUser = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/users/get-user`, { decodedToken });
            setUser(response.data.user);
        } catch (error) {
            setAlert({ open: true, severity: "error", message: `Error fetching user details. ${error}` });
        }
    }

    useEffect(() => {
        fetchCategories();
        getUser();
    }, [])

    useEffect(() => {
        setTitle(article.title ?? null);
        setAltTitle(article.altTitle ?? null);
        setContent(article.content ?? null);
        setSource(article.source ?? null);
        setCategory(article.category ?? null);
        setIsStatic(article.static ?? null);
    }, [article]);


    const handleResetCount = () => {
        return;
    }

    const handleUpdateArticle = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/${type === "articles" ? "manage-articles/update-article" : "drafts/update-draft" }`, {
                _id: article._id,
                title,
                altTitle,
                content,
                source,
                category,
                static: isStatic,
                updater: user.username,
                updated: new Date().getTime()
            });

            const updatedArticle = response.data;

            if (updatedArticle) {
                setArticles((prevArticles) => {
                    return prevArticles.map((art) => (art._id === article._id ? updatedArticle : art));
                });
            }

            setShowEditModal(false);
            setAlert({ open: true, severity: "success", message: "Article updated successfully!" });
        } catch (error) {
            console.log(error);
            setAlert({ open: true, severity: "error", message: `Error updating the article. ${error}` });
        }
    };




    return (
        <>
            <CategoryModal categories={categories} setCategories={setCategories} setShowCategoryModal={setShowCategoryModal} showCategoryModal={showCategoryModal} />
            <Modal
                open={showEditModal}
                onClose={() => setShowEditModal(false)}
            >
                <ModalDialog style={{ overflowX: "scroll" }}>
                    <ModalClose />
                    <Typography level="h3">Edit Article</Typography>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControl>
                                <Typography level="h5">Article Specifications</Typography>
                                <FormLabel>Article ID</FormLabel>
                                <Input value={article._id} disabled />
                                <FormLabel>Static</FormLabel>
                                <Select defaultValue={isStatic} size="small" onChange={(e) => setIsStatic(e.target.value)}>
                                    <MenuItem value={true}>true</MenuItem>
                                    <MenuItem value={false}>false</MenuItem>
                                </Select>
                                <FormControl>
                                    <FormLabel>Request Count (resettable)</FormLabel>
                                    <Grid container sx={{ padding: 0 }} spacing={1}>
                                        <Grid item>
                                            <Input value={article.requestCount} disabled sx={{ width: "110px" }} />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="soft" disabled color="warning" onClick={handleResetCount}>Reset</Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Request Count (total)</FormLabel>
                                    <Input value={article.requestCount} disabled />
                                </FormControl>
                                <FormLabel>Created by</FormLabel>
                                <Input disabled value={article.creator ? article.creator : "null"} />
                                <FormLabel>Created</FormLabel>
                                <Input disabled value={article.created ? new Date(article.created).toLocaleString() : "null"} />
                                <FormLabel>Updated by</FormLabel>
                                <Input disabled value={article.updater ? article.updater : "null"} />
                                <FormLabel>Last Updated</FormLabel>
                                <Input value={new Date(article.updated).toLocaleString()} disabled />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ minWidth: 300 }}>
                                <Typography level="h5">Article Content</Typography>
                                <FormLabel>Title</FormLabel>
                                <Textarea value={title} onChange={(e) => setTitle(e.target.value)} />
                                <FormLabel>Alternative Title</FormLabel>
                                <Textarea placeholder="t.ex. Sveriges största stad" value={altTitle} onChange={(e) => setAltTitle(e.target.value)} />
                                <FormLabel>Content</FormLabel>
                                <Textarea value={content} minRows={3} onChange={(e) => setContent(e.target.value)} />
                                <FormControl>
                                    <FormLabel>Category (<Link onClick={() => setShowCategoryModal(true)}>manage</Link>)</FormLabel>
                                    <Select sx={{ minWidth: 200 }} placeholder="Choose category..." value={category} onChange={(e) => { setCategory(e.target.value); }} size="small" onClick={fetchCategories}>
                                        {categories.map((cat, index) => {
                                            return (
                                                <MenuItem key={index} variant="soft" color="neutral" value={cat.name}>{cat.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormLabel>Source</FormLabel>
                                <Input value={source} onChange={(e) => setSource(e.target.value)} />
                                <Grid container spacing={1} sx={{ paddingLeft: 0 }}>
                                    <Grid item>
                                        <Button variant="soft" color="success" onClick={handleUpdateArticle}>Save</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="soft" color="danger" disabled>Delete</Button>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                </ModalDialog>
            </Modal>
            <SnackbarAlert alert={alert} setAlert={setAlert} />
        </>

    )
}

export default EditArticleModal;