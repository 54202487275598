import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import TableRowWithCell from "./TableRowWithCell";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import PreviewModal from "../modals/PreviewModal";
import EditArticleModal from "../modals/EditArticleModal";
import SnackbarAlert from "../Alert";

import { Sheet, Button, IconButton, Link, Typography, FormControl, FormLabel } from "@mui/joy";
import { Autocomplete, TextField, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, TableFooter, TablePagination, Divider, Grid, Select, MenuItem } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ArticleList = ({ articles, setArticles, type }) => {
    const [openRow, setOpenRow] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredArticles, setFilteredArticles] = useState(articles);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [previewArticle, setPreviewArticle] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editArticle, setEditArticle] = useState({});
    const [sortOrder, setSortOrder] = useState("default");
    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" });

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event, newValue) => {
        setSearchTerm(newValue);
        if (newValue) {
            const filtered = articles.filter((article) =>
                article.title.toLowerCase().includes(newValue.toLowerCase())
            );
            setFilteredArticles(filtered);
        } else {
            setFilteredArticles(articles);
        }
    };

    const handleRedirectArticle = (article) => {
        navigate(`/article/${encodeURIComponent(article.title)}`, { state: { article: article } });
    }

    const handleApproveDraft = async (draft) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/drafts/approve-draft`, { draftId: draft._id });
            if (response.status === 201) {
                setArticles([...articles, draft]);
                setAlert({ open: true, severity: "success", message: "The draft was successfully merged to article collection." });
            } else {
                console.log(response.data.message);
                setAlert({ open: true, severity: "error", message: `Error merging draft. ${response.data.error}` });
            }
        } catch (error) {
            console.log(error);
            setAlert({ open: true, severity: "error", message: `Error creating article. ${error}` });
        }
    }

    const handlePreview = (article) => {
        setPreviewArticle(article);
        setShowPreviewModal(true);
    };

    const handleShowEditModal = (article) => {
        setEditArticle(article);
        setShowEditModal(true);
    }

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const sortArticles = () => {
        if (sortOrder === "default") {
            setFilteredArticles(articles);
        } else {
            const sortedArticles = [...filteredArticles].sort((a, b) => {
                if (sortOrder === "created") {
                    return new Date(b.created) - new Date(a.created);
                } else if (sortOrder === "updated") {
                    return new Date(b.updated) - new Date(a.updated);
                } else {
                    return 0;
                }
            });
            setFilteredArticles(sortedArticles);
        }
    };

    useEffect(() => {
        sortArticles();
    }, [sortOrder, filteredArticles]);

    useEffect(() => {
        setFilteredArticles(articles);
    }, [articles]);


    return (
        <>


            <Autocomplete
                freeSolo
                options={articles.map((article) => article.title)}
                value={searchTerm}
                onChange={handleSearchChange}
                renderInput={(params) => (
                    <TextField {...params} label="Search Articles" variant="outlined" />
                )}
            />
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="collapsible table">
                    <TableHead>
                        <TableRow style={{ zIndex: 100 }}>
                            <TableCell />
                            <TableCell><b>#</b></TableCell>
                            <TableCell><b>title</b></TableCell>
                            <TableCell><b>content</b></TableCell>
                            <TableCell><b>static</b></TableCell>
                            <TableCell
                                style={{
                                    position: "sticky",
                                    right: 0,
                                    backgroundColor: "#fbfbfb",
                                    zIndex: 1,
                                }}
                            >
                                <FormControl>
                                    <b>Sort by</b>
                                    <Select
                                        select
                                        value={sortOrder}
                                        onChange={handleSortOrderChange}
                                        variant="outlined"
                                        size="small"
                                    >
                                        <MenuItem value="default">None</MenuItem>
                                        <MenuItem value="created">Date (created)</MenuItem>
                                        <MenuItem value="updated">Date (updated)</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? filteredArticles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredArticles).map((article, index) => {
                            return (
                                <React.Fragment key={article._id}>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                variant="soft"
                                                color="neutral"
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpenRow(openRow === index ? -1 : index)}
                                            >
                                                {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{article.title ?? "null"}</TableCell>
                                        <TableCell>{article.content ?? "null"}</TableCell>
                                        <TableCell>{article.static !== undefined && article.static !== null ? article.static.toString() : "null"}</TableCell>
                                        <TableCell
                                            style={{
                                                position: "sticky",
                                                right: 0,
                                                // backdropFilter: "blur(3px)",
                                                backgroundColor: "#fbfbfb",
                                                zIndex: 0,
                                            }}
                                        >
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                                {type === "articles" ?
                                                    <Button variant="soft" color="success" onClick={() => handleRedirectArticle(article)}>Show Live</Button>
                                                    :
                                                    <Button variant="soft" color="success" onClick={() => handleApproveDraft(article)}>Merge</Button>
                                                }

                                                <Button variant="soft" color="success" onClick={() => handlePreview(article)}>
                                                    Preview
                                                </Button>
                                                <IconButton variant="soft" color="neutral" onClick={() => handleShowEditModal(article)}><EditIcon /></IconButton>

                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                            colSpan={7}
                                        >
                                            <Collapse
                                                in={openRow === index}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <Box sx={{ margin: 2 }}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Table size="small" aria-label="collapsible content">
                                                                <TableHead>Article Specifications</TableHead>
                                                                <TableBody>
                                                                    <TableRowWithCell heading="Article ID" data={article._id ?? "null"} />
                                                                    <TableRowWithCell heading="Static" data={article.static !== undefined && article.static !== null ? article.static.toString() : "null"} />
                                                                    <TableRowWithCell heading="Request Count" data={article.requestCount ?? "null"} />
                                                                    <TableRowWithCell heading="Source" data={<Link color="neutral" href={article.source}><Typography level="body3">{article.source ?? "null"}</Typography></Link>} />
                                                                    <TableRowWithCell heading="Category (not implemented)" data={article.category ?? "null"} />
                                                                    <TableRowWithCell heading="Created by" data={article.creator ?? "null"} />
                                                                    <TableRowWithCell heading="Created" data={new Date(article.created).toLocaleDateString() ?? "null"} />
                                                                    <TableRowWithCell heading="Updated by" data={article.updater ?? "null"} />
                                                                    <TableRowWithCell heading="Updated" data={article.updated ? new Date(article.updated).toLocaleDateString() : "null"} />
                                                                </TableBody>
                                                                <br />
                                                                <TableHead>Discontinued</TableHead>
                                                                <TableBody>
                                                                    <TableRowWithCell heading="Region (discontinued)" data={article.region ?? "null"} />
                                                                    <TableRowWithCell heading="Option (discontinued)" data={article.option ?? "null"} />
                                                                    <TableRowWithCell heading="Input (discontinued)" data={article.input ?? "null"} />
                                                                    <TableRowWithCell heading="See also (discontinued)" data={article.seeAlso ?? "null"} />
                                                                    <TableRowWithCell heading="Keyword (old)" data={article.keyword ?? "null"} />
                                                                </TableBody>
                                                                <TableHead>Actions</TableHead>
                                                                <Grid container>
                                                                    <Grid item><IconButton color="danger" onClick={() => setShowDeleteModal(true)}><DeleteIcon /></IconButton></Grid>
                                                                    <Grid item><IconButton variant="soft" color="neutral" onClick={() => handleShowEditModal(article)}><EditIcon /></IconButton></Grid>
                                                                </Grid>
                                                                <ConfirmDeleteModal removalObject={article.title} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deletePath="api/manage-articles/delete-article" id={article._id} setArray={setArticles} array={articles} setAlert={setAlert} />
                                                            </Table>
                                                        </Grid>

                                                    </Grid>



                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={7}
                                count={articles.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <PreviewModal showPreviewModal={showPreviewModal} setShowPreviewModal={setShowPreviewModal} article={previewArticle} />
            <EditArticleModal showEditModal={showEditModal} setShowEditModal={setShowEditModal} article={editArticle} setArticles={setArticles} type={type} />
            <SnackbarAlert alert={alert} setAlert={setAlert} />
        </>

    )
}

export default ArticleList;