import React from "react";
import { TableRow, TableCell } from "@mui/material";

const TableRowWithCell = (props) => {
    return (
        <TableRow>
            <TableCell component="th" scope="row"><b>{props.heading}</b></TableCell>
            <TableCell>{props.data}</TableCell>
        </TableRow>
    )
}

export default TableRowWithCell;